





























import Vue from "vue";

export default Vue.extend({
  name: "Contact",
  props: {
    name: {
      type: String
    },
    mail: {
      type: String
    },
    twitter: {
      type: String
    }
  },
});
